import classes from './style.module.scss';
import classNames from "classnames";
import {useState} from "react";
import {Card} from "../../components/Card";
import {CloseBtn} from "../../components/CloseBtn";

export const Cards = ({className}) => {
    const cls = classNames(classes.root, { [className]: className });
    const [selectedCard, setSelectedCard] = useState(undefined);
    const data = [
        {
            url: <iframe src={`https://player.vimeo.com/video/664315400?loop=1&title=0&byline=0&portrait=0`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />,
            id: 1,
        },
        {
            url: <iframe src={`https://player.vimeo.com/video/664316809?loop=1&title=0&byline=0&portrait=0`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />,
            id: 2,
        },
        {
            url: <iframe src={`https://player.vimeo.com/video/664317863?loop=1&title=0&byline=0&portrait=0`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />,
            id: 3,
        },
        {
            url: <iframe src={`https://player.vimeo.com/video/664318897?loop=1&title=0&byline=0&portrait=0`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />,
            id: 4,
        },
    ]

    return (
        <div className={cls}>
            <div className={classes.blockOne}>
                <div className={classes.textOne}>Итак, начнем!</div>
                <div className={classes.textTwo}>Что ожидает рынок недвижимости в 2022 году?</div>
                <div className={classes.visibleVideo}>
                    <iframe src="https://player.vimeo.com/video/664309402?loop=1&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />
                </div>
            </div>
            <div className={classes.blockTwo}>
                <div className={classes.textThree}>Каким станет для меня 2022 год?</div>
                <div className={classes.textFour}>Подумайте о данном вопросе и интуитивно <br className={classNames('brDesktop')}/>выберите одну из четырех карт, нажав на нее </div>
                <div className={classes.wrapCards}>
                    {data.map((el, i) => {
                        return (
                            <Card key={i} setSelectedCard={setSelectedCard} id={i} />
                        )
                    })}
                </div>
            </div>

            <div className={classNames(classes.popup, { [classes.popup_open]: Number.isInteger(selectedCard) })}>
                <div className={classes.iFrameWrap}>
                    {data[selectedCard] && data[selectedCard].url }
                </div>
                <div className={classes["lds-spinner"]}>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
                <CloseBtn className={classes.CloseBtn} onClick={() => {setSelectedCard(prev => undefined)}} />

            </div>
        </div>
    )
}
