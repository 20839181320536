import classes from './style.module.scss';
import classNames from "classnames";
import {useEffect, useState} from "react";
import NumberFormat from 'react-number-format';
import {Popup} from "../../components/Popup";
import axios from "axios";
import {useWindowSize} from "../../library";
import {sizes} from "../../data/sizes";


export const LotteryForm = ({className}) => {
    const cls = classNames(classes.root, { [className]: className });
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState(undefined);
    const [isHovered, setIsHovered] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isCompanyValid, setIsCompanyValid] = useState(true);
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const [brokerType, setBrokerType] = useState('');
    const [isPopupClosed, setIsPopupClosed] = useState(true);
    const [isRadioError, setIsRadioError] = useState(false);
    const [typeMessage, setTypeMessage] = useState(''); // 'suссess' 'error' 'server-error'
    const [width, height] = useWindowSize();
    const checkIsEmpty = (value) => {
        const splitValue = value.split(' ').join('').length;
        const simpleValue = value.length;

        return simpleValue > splitValue
    }
    const checkHasNumber = (value) => {
        return value.match(/\d+/);
    }
    let t1 = width >= sizes.widthNotebook ? 1 : null;
    let t2 = width >= sizes.widthNotebook ? 2 : null;
    let t3 = width >= sizes.widthNotebook ? 3 : null;
    let t4 = width >= sizes.widthNotebook ? 4 : null;

    useEffect(() => {
        const clearPhoneLength = phone && Number.isInteger(Number(phone.replace(/\D/g, ''))) && phone.replace(/\D/g, '').length;

        if (company.trim().length === 0 && company.length > 0) {
            setIsCompanyValid(false)
        } else {
            setIsCompanyValid(true)
        }

        if (checkIsEmpty(name)
            || checkIsEmpty(surname)
            || checkHasNumber(name)
            || checkHasNumber(surname)
            || name.trim().length === 0
            || surname.trim().length === 0
            || company.trim().length === 0
            || (clearPhoneLength !== 11 )
            || brokerType === '') {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [name, surname, company, phone, brokerType])

    const getData = async () => {
        try {
            const data = await axios.post(
                "https://bitrix.stonehedge.ru/lkb/api/v1/",
                {
                    query: `mutation {    addEventParticipant(\n       eventCode: \"tarot\",\n       lastName: \"${surname}\",\n       firstName: \"${name}\",\n       phoneNumber: \"${phone.replace(/\D/g, '')}\",\n       companyName: \"${company === 'Частный брокер' ? '' : company}\",\n       brokerType: \"${brokerType}\"\n   )\n}`,
                    variables: {},
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (data && data.data.data.addEventParticipant) {
                setTypeMessage(prev => 'suссess');
                setIsPopupClosed(prev => false);
            } else if (data && data.data.errors[0].code === 906) {
                setTypeMessage(prev => 'error');
                setIsPopupClosed(prev => false);
            } else {
                setTypeMessage(prev => 'server-error');
                setIsPopupClosed(prev => false);
            }

        } catch (error) {
            setTypeMessage(prev => 'server-error');
            setIsPopupClosed(prev => false);
        }
    };

    return (
        <div className={cls}>
            <input
                tabIndex={t1}
                className={classNames(classes.inputTextClass, {[classes.error]: checkIsEmpty(name) || checkHasNumber(name), [classes.valid]: !checkIsEmpty(name) && !checkHasNumber(name) && name.trim().length !== 0})}
                placeholder={"Имя*"}
                type="text"
                value={name}
                onChange={e => {
                    setName(prev => e.target.value);
                }}
            />
            <input
                tabIndex={t2}
                className={classNames(classes.inputTextClass, {[classes.error]: checkIsEmpty(surname) || checkHasNumber(surname), [classes.valid]: !checkIsEmpty(surname) && !checkHasNumber(surname) && surname.trim().length !== 0})}
                placeholder={"Фамилия*"}
                type="text"
                value={surname}
                onChange={e => {
                    setSurname(prev => e.target.value);
                }}
            />
            <NumberFormat
                tabIndex={t4}
                className={classNames(classes.inputTextClass, {[classes.valid]: phone && Number.isInteger(Number(phone.replace(/\D/g, ''))) && phone.replace(/\D/g, '').length === 11})}
                placeholder={"Телефон*"}
                format="+7 ### ###-##-##"
                mask="_"
                value={phone}
                onChange={e => {
                    setPhone(prev => e.target.value);
                }}
                allowEmptyFormatting={isHovered}
                onMouseDown={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
            <input
                tabIndex={t3}
                className={classNames(classes.inputTextClass, {[classes.error]: !isCompanyValid, [classes.valid]: company.trim().length !== 0})}
                placeholder={"Название компании*"}
                type="text"
                value={company}
                disabled={brokerType === "private_broker"}
                onChange={e => {
                    setCompany(prev => e.target.value);
                }}
                onClick={() => {
                    if (brokerType === '') {
                        setIsRadioError(true);
                    }
                }}
            />
            <div
                className={classNames(classes.radio, {[classes.radioError]: isRadioError})}
                onClick={() => setIsRadioError(false)}
            >
                <input
                    className={'visually-hidden'}
                    id={"private_broker"}
                    type="radio"
                    value={"private_broker"}
                    checked={brokerType === "private_broker"}
                    onChange={(event) => {
                        setBrokerType(event.target.value);
                        setIsRadioError(false);
                        setCompany('Частный брокер');
                    }}
                />
                <label htmlFor="private_broker">Частный брокер</label>
            </div>
            <div className={classNames(classes.radio, {[classes.radioError]: isRadioError})}>
                <input
                    className={'visually-hidden'}
                    id={"real_estate_agency"}
                    type="radio"
                    value={"real_estate_agency"}
                    checked={brokerType === "real_estate_agency"}
                    onChange={(event) => {
                        setBrokerType(event.target.value);
                        setIsRadioError(false);
                        if (company === 'Частный брокер') {
                            setCompany('');
                        }
                    }}
                />
                <label htmlFor="real_estate_agency">Агентство недвижимости</label>
            </div>
            <button
                className={classes.sendBtn}
                disabled={isDisabled || !isCheckBoxChecked}
                onClick={async () => {
                    setIsDisabled(prev => true);
                    await getData();
                }}
            >
                Зарегистрироваться
            </button>
            <div className={classNames(classes.checkBoxDisabled, {[classes.checkBoxBounce]: !isDisabled && !isCheckBoxChecked, [classes.checkBoxChecked]: isCheckBoxChecked})} onClick={() => {
                setIsCheckBoxChecked(prev => !prev)
            }}>
               Я согласен с обработкой персональных данных
            </div>

            <Popup
                typeMessage={typeMessage}
                isPopupClosed={isPopupClosed}
                setIsPopupClosed={setIsPopupClosed}
            />
        </div>
    )
}