import classes from './style.module.scss';
import classNames from "classnames";

export const Avatar = ({className, href, src, alt}) => {
    const cls = classNames(classes.root, { [className]: className });
    return (
        <a href={href} className={cls} target={"_blank"} rel={"noreferrer"}>
            <img
                src={src}
                alt={alt}
            />
        </a>
    )
}