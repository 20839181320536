import classes from './style.module.scss';
import classNames from "classnames";
import {stoneLogo} from "../../img/inlineSvg";

export const Header = ({className}) => {
    const cls = classNames(classes.root, { [className]: className });
    return (
        <div className={cls}>
            <div className={classes.logo} dangerouslySetInnerHTML={{ __html: stoneLogo}} />
            <div className={classes.textOne}>Новогодний подарок агентствам <br className={classNames('brMobile', 'brDesktop')}/>недвижимости и частным брокерам </div>
            <div className={classes.textTwo}>Бизнес-прогноз <br className={'brMobile'} />для рынка недвижимости <br className={classNames('brMobile', 'brDesktop')} />от звездного таролога</div>
            <div className={classes.textThree}>На 2022 год</div>
        </div>
    )
}