import classes from './style.module.scss';
import classNames from "classnames";
import {CloseBtn} from "../CloseBtn";

export const Popup = ({className, typeMessage, isPopupClosed, setIsPopupClosed}) => {

    const cls = classNames(classes.root, {[classes.displayNone]: isPopupClosed, [className]: className });
    return (
        <div className={cls}>
            <CloseBtn className={classes.CloseBtn} onClick={() => setIsPopupClosed(true)} />
            {typeMessage === 'suссess' ?
                <div className={classNames(classes.message)}>
                    <div className={classes.success} />
                    <div className={classes.title}>Ваша регистрация пройдена.</div>
                    <div className={classes.text}>Номер участника будет направлен <br className={classNames('brDesktop')} />вам в sms-сообщении</div>
                </div>
            : typeMessage === 'error' ?
                <div className={classNames(classes.message)}>
                    <div className={classes.error} />
                    <div className={classes.title}>Пользователь с таким номером телефона <br className={classNames('brDesktop')} />уже зарегистрирован,</div>
                    <div className={classes.text}>просьба проверить актуальность
                        данных</div>
                </div>
            :   <div className={classNames(classes.message)}>
                    <div className={classes.error} />
                    <div className={classes.title}>Что-то пошло не так,</div>
                    <div className={classes.text}>повторите попытку позже</div>
                </div>
            }
        </div>
    )
}