import classes from './style.module.scss';
import classNames from "classnames";
import {Avatar} from "../../components/Avatar";

export const Guest = ({className}) => {
    const cls = classNames(classes.root, { [className]: className });
    return (
        <div className={cls}>
            <div className={classes.textOne}>Приумножайте шансы на успех, <br className={classNames( 'brDesktop', 'brMobile')}/>зная повороты судьбы на рынке <br className={classNames( 'brMobile')}/>недвижимости</div>
            <div className={classes.textTwo}>Ваш подарок от STONE HEDGE — <br className={classNames('brDesktop', 'brMobile')}/>бизнес-прогноз на ТАРО</div>
            <div className={classes.textThree}>
                <div>Юлия Азизбаева</div>
                <div>Звездный таролог</div>
            </div>
            <div className={classes.textFour}>Консультирует селебрити и бизнесменов,
                находит ответы на любые вопросы,
                предостерегает от неправильных решений,
                говорит по душам и с пользой для бизнеса.
                Имеет два высших образования:
                лингвистическое и финансовое.
            </div>
            <Avatar className={classes.avatar}
                    href={'https://www.instagram.com/julia_azizbaeva/'}
                    src={"guest.jpg"}
                    alt={"Юлия Азизбаева"}
            />
            <div className={classes.star} />
        </div>
    )
}