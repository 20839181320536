import classes from './style.module.scss';
import classNames from "classnames";
import {stoneLogo} from "../../img/inlineSvg";

export const Footer = ({className}) => {
    const cls = classNames(classes.root, { [className]: className });
    return (
        <div className={cls}>
            <div className={classes.textOne}>Спасибо, что были с нами в этом году, <br className={classNames( 'brMobile')}/>и до встречи в 2022!</div>
            <div className={classes.logo} dangerouslySetInnerHTML={{ __html: stoneLogo}} />
            <div className={classes.textTwo}>Будьте в курсе последних новостей <br className={classNames( 'brMobile')}/>by STONE HEDGE. <br className={classNames('brDesktop')}/>Подписывайтесь <br className={classNames( 'brMobile')}/>на нас в социальных сетях</div>
            <a target={"_blank"} rel={"noreferrer"} href={'https://www.instagram.com/stone_by_stonehedge/'} className={classNames( classes.socials, classes.inst)} />
            <a target={"_blank"} rel={"noreferrer"} href={'https://t.me/stone_by_stonehedge'} className={classNames( classes.socials, classes.tg)} />
            <a target={"_blank"} rel={"noreferrer"} href={'https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Fstone.by.stonehedge%2F'} className={classNames( classes.socials, classes.fb)} />
            <div className={classes.textThree}>АО «Стоунхедж». <br className={classNames( 'brMobile')}/>Все права защищены.</div>
        </div>
    )
}