import classes from './style.module.scss';
import classNames from "classnames";
import {undercardDecor} from "../../img/inlineSvg";

export const Card = ({className, setSelectedCard, id}) => {
    const cls = classNames(classes.root, { [className]: className });
    return (
        <>
            <div className={cls} onClick={() => setSelectedCard(id)}>
                <div className={classes.undercard} dangerouslySetInnerHTML={{__html: undercardDecor}} />
            </div>
        </>
    )
}