import {Header} from "./sections/Header";
import {TopManager} from "./sections/TopManager";
import {Guest} from "./sections/Guest";
import {Cards} from "./sections/Cards";
import {LotteryTitle} from "./sections/LotteryTitle";
import {LotteryForm} from "./sections/LotteryForm";
import {LotteryLinks} from "./sections/LotteryLinks";
import {LotteryShare} from "./sections/LotteryShare";
import {Footer} from "./sections/Footer";

function App() {
    return (
        <div className="page-wrapper">
            <Header />
            <TopManager />
            <Guest />
            <Cards />
            <LotteryTitle />
            <LotteryForm />
            <LotteryLinks />
            <LotteryShare />
            <Footer />
        </div>
    );
}

export default App;
