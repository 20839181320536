import classes from './style.module.scss';
import classNames from "classnames";

export const TopManager = ({className}) => {
    const cls = classNames(classes.root, { [className]: className });
    return (
        <div className={cls}>
            <div className={classes.textOne}>Друзья, от всей команды STONE HEDGE <br className={classNames('brMobile', 'brDesktop')}/>и от себя лично хочу поздравить вас <br className={classNames('brMobile', 'brDesktop')}/>с наступающим 2022 годом!</div>
            <div className={classes.textTwo}>Каждый день меняются законы, тенденции, тренды. Логических
                решений уже недостаточно, на рынке недвижимости все больше
                работает интуиция, и наш бизнес-расклад на таро покажет,
                где возможны сложности, и как найти верное решение.
                Пусть Новый 2022 год для вас будет наполнен только приятными
                сюрпризами, и в каждом дне будет немного волшебства.
                Вдохновляющих событий и приятных моментов.</div>
            <div className={classes.manager} />
            <div className={classes.textThree}>
                <div>Анастасия Малкова</div>
                <div>Управляющий партнер STONE HEDGE</div>
            </div>
        </div>
    )
}