import classes from './style.module.scss';
import classNames from "classnames";
import {useState} from "react";

export const LotteryShare = ({className}) => {
    const cls = classNames(classes.root, { [className]: className });

    const [copied, setCopied] = useState(false);

    function copy() {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(true);
    }
    return (
        <div className={cls}>
            <div className={classes.textOne}>Наш подарок <br className={classNames( 'brMobile')}/>несекретный</div>
            <div className={classes.textTwo}>
                Делитесь бизнес-прогнозом <br className={classNames( 'brMobile')}/>от звездного таролога с коллегами <br className={classNames('brMobile', 'brDesktop')}/>по рынку и друзьями, чтобы каждый <br className={classNames( 'brMobile')}/>знал наиболее благоприятное <br className={classNames('brDesktop')}/>время <br className={classNames( 'brMobile')}/>в 2022 году для реализации <br className={classNames( 'brMobile')}/>грандиозных планов и быстрого <br className={classNames('brMobile', 'brDesktop')}/>заключения многомиллионных <br className={classNames( 'brMobile')}/>сделок.
            </div>
            <a className={classes.fbShare} href="https://www.facebook.com/sharer/sharer.php?u=https://stonehedge.moscow/" target="_blank" rel={"noreferrer"}>Поделиться</a>
            <button className={classes.copyLink} onClick={copy}>Копировать ссылку</button>
            <div className={ classNames(classes.info, { [classes.infoVisible]: copied }) }>Ссылка скопирована</div>
        </div>
    )
}
