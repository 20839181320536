import classes from './style.module.scss';
import classNames from "classnames";

export const LotteryTitle = ({className}) => {
    const cls = classNames(classes.root, { [className]: className });
    return (
        <div className={cls}>
            <div className={classes.firstText}>Новогодняя лотерея</div>
            <div className={classes.secondText}>Выиграйте персональную консультацию <br className={'brMobile'} />со звездным тарологом</div>
            <div className={classes.thirdText}>Понравился наш бизнес-прогноз? <br className={classNames( 'brMobile')}/>Но у многих наверняка остались <br className={classNames('brMobile')}/>вопросы <br className={classNames( 'brDesktop')}/>из личной сферы интересов, <br className={classNames( 'brMobile')}/>на которые также хочется получить <br className={classNames( 'brMobile')}/>ответы. <br className={classNames('brDesktop')}/>Оставьте ваши контакты ниже, <br className={classNames('brMobile')}/>и пусть вам сопутствует удача.</div>
        </div>
    )
}