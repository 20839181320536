import classes from './style.module.scss';
import classNames from "classnames";

export const LotteryLinks = ({className}) => {
    const cls = classNames(classes.root, { [className]: className });
    return (
        <div className={cls}>
            <div className={classes.topBlock}>
                <div className={classes.winnerText}>Победитель будет объявлен <br className={classNames( 'brMobile')}/><span>10 января 2022 года</span> <br className={classNames( 'brMobile', 'brDesktop')}/>в нашем Instagram аккаунте.</div>
                <a href="https://www.instagram.com/stone_by_stonehedge/" target={"_blank"} rel={"noreferrer"}>
                    <div className={classes.instaLink}>@stone_by_stonehedge</div>
                </a>
            </div>
        </div>
    )
}